export function isInViewport(container, element, partial) {
  if (!container || !element) return;
  //Get container properties
  let cTop = container.scrollTop;
  let cBottom = cTop + container.clientHeight;

  //Get element properties
  let eTop = element.offsetTop;
  let eBottom = eTop + element.clientHeight;

  //Check if in view
  let isTotal = eTop >= cTop && eBottom <= cBottom;
  let isPartial =
    partial &&
    ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));

  //Return outcome
  return isTotal || isPartial;
}

export function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}
